import { getWalletHistory, TWalletTransaction } from "context/WalletContext/Api";
import { useCallback, useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";

type TWalletHistoryItem = TWalletTransaction & {
	page: number;
};

const useWalletHistory = ({ infinite = false, size = 10 }) => {
	const [walletHistory, setWalletHistory] = useState<TWalletHistoryItem[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(size);
	const [resetTableScroll, setResetTableScroll] = useState(false);
	const [isWalletHistoryLoading, setIsWalletHistoryLoading] = useState(false);
	const [sortBy, setSortBy] = useState<"asc" | "desc">("desc");
	const [paymentType, setPaymentType] = useState<TWalletTransaction["type"] | undefined>();
	const [debouncedSearchText, setSearchText] = useDebounceValue("", 600);

	const fetchWalletHistory = async (payload: Parameters<typeof getWalletHistory>[0]) => {
		try {
			setIsWalletHistoryLoading(true);
			const response = await getWalletHistory(payload);
			setTotalCount(parseInt(response.data.data.metadata.totalCount));
			return response.data.data.data;
		} catch (error) {
			console.log("🚀 ~ fetchWalletHistory ~ error", error);
		} finally {
			setIsWalletHistoryLoading(false);
		}
	};

	const updateWalletHistory = (history: TWalletTransaction[], page: number, append?: boolean) => {
		const _history: TWalletHistoryItem[] = history.map((_) => ({ ..._, page }));
		if (!append) setWalletHistory(_history);
		else
			setWalletHistory((prev) => {
				if (prev.length === 0) return _history;
				if (_history.length === 0) return prev;
				const startPage = prev[0].page;
				const lastPage = prev.at(-1)?.page ?? startPage;
				if (startPage - page === 1) {
					prev.splice(0, 0, ..._history);
				}
				if (page - lastPage === 1) {
					prev.push(..._history);
				}

				return [...prev];
			});
	};

	const handleSortByChange = (val: typeof sortBy) => {
		Mixpanel.track(MIXPANEL_EVENTS.WALLET_HISTORY_FILTER, { sortBy: val });
		setSortBy(val);
	};

	const handlePaymentTypeChange = (val: typeof paymentType) => {
		Mixpanel.track(MIXPANEL_EVENTS.WALLET_HISTORY_FILTER, { paymentType: val });
		setPaymentType(val);
	};

	const handlePageChange = useCallback(
		async (page: number, pSize = pageSize) => {
			setResetTableScroll(false);
			const history = await fetchWalletHistory({
				page,
				sortBy,
				pageSize: pSize,
				type: paymentType,
				searchText: debouncedSearchText
			});
			if (history) {
				setPage(page);
				setPageSize(pSize);
				updateWalletHistory(history, page, infinite);
			}
		},
		[debouncedSearchText, infinite, pageSize, paymentType, sortBy]
	);

	useEffect(() => {
		setResetTableScroll(true);
		fetchWalletHistory({
			page: 1,
			pageSize,
			sortBy,
			type: paymentType,
			searchText: debouncedSearchText
		}).then((history) => {
			if (history) {
				updateWalletHistory(history, 1);
				setPage(1);
			}
		});
	}, [pageSize, sortBy, paymentType, debouncedSearchText, infinite]);

	return {
		walletHistory,
		isWalletHistoryLoading,
		totalCount,
		page,
		pageSize,
		resetTableScroll,
		sortBy,
		paymentType,
		setSearchText,
		setPageSize,
		handlePageChange,
		handlePaymentTypeChange,
		handleSortByChange
	};
};

export default useWalletHistory;

