import { DOCUMENTS_TYPE_MAP } from "helpers/constant";
import convertCountrySymbol from "./convertCountrySymbol";

type FileObj = {
	file_name: string;
	file_size: number;
	file_type: string;
	file_upload_status: "" | "failed" | "uploading" | "uploaded";
	file_valid: boolean;
};

export const isNumber = (val: any) => typeof val === "number" && !isNaN(val);

export const capitalizeFirstLetter = (str: string) => {
	try {
		return str.charAt(0).toUpperCase() + str.slice(1);
	} catch (err) {
		return str;
	}
};

export const capitalizeEachWord = (str: string) => {
	try {
		return str
			.split(" ")
			.map((_) => capitalizeFirstLetter(_))
			.join(" ");
	} catch (err) {
		return str;
	}
};

export const IndianSystemPrice = (price: number) => {
	return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDocDetailsFromDocumentsTypes = (docType: string): TDocDetails => {
	return (
		DOCUMENTS_TYPE_MAP[docType as DocumentsTypes] ?? {
			name: capitalizeEachWord(docType.split("_").join(" ").toLocaleLowerCase()),
			description: "Todo"
		}
	);
};

export async function copyText(text: string) {
	try {
		await navigator.clipboard.writeText(text);
	} catch (error) {
		console.error("Failed to copy: ", error);
	}
}

export function numberToWords(no: number): string {
	const words: Record<number, string> = {
		0: "",
		1: "One",
		2: "Two",
		3: "Three",
		4: "Four",
		5: "Five",
		6: "Six",
		7: "Seven",
		8: "Eight",
		9: "Nine",
		10: "Ten",
		11: "Eleven",
		12: "Twelve",
		13: "Thirteen",
		14: "Fourteen",
		15: "Fifteen",
		16: "Sixteen",
		17: "Seventeen",
		18: "Eighteen",
		19: "Nineteen",
		20: "Twenty",
		30: "Thirty",
		40: "Forty",
		50: "Fifty",
		60: "Sixty",
		70: "Seventy",
		80: "Eighty",
		90: "Ninety",
		100: "Hundred",
		1000: "Thousand",
		100000: "Lakh",
		10000000: "Crore"
	};

	if (no === 0) {
		return "";
	}

	let noValue = "";
	let highNo = no;
	let remainNo = 0;
	let value = 100;
	let value1 = 1000;

	while (no >= 100) {
		if (value <= no && no < value1) {
			noValue = words[value];
			highNo = Math.floor(no / value);
			remainNo = no % value;
			break;
		}
		value = value1;
		value1 *= 100;
	}

	if (words[highNo]) {
		return words[highNo] + " " + noValue + " " + numberToWords(remainNo);
	} else {
		const unit = highNo % 10;
		const ten = Math.floor(highNo / 10) * 10;
		return (no < 100 ? " & " : "") + words[ten] + " " + words[unit] + " " + noValue + " " + numberToWords(remainNo);
	}
}

export { convertCountrySymbol };


