import {
	CaretDoubleLeft,
	CaretRight,
	FileSearch,
	Gear,
	House,
	Info,
	Laptop,
	Megaphone,
	Phone,
	ShieldCheck,
	SignOut,
	Stack,
	UploadSimple,
	User
} from "@phosphor-icons/react";
import { Dropdown, Layout, Menu, Switch, Tooltip } from "antd";
import clsx from "clsx";
import Loader from "components/common/Loader";
import toast from "components/common/Toaster";
import { useGlobalContext, useWallet } from "context";
import React, { useEffect, useRef, useState } from "react";
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";
import { useBoolean } from "usehooks-ts";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import sentry from "utils/sentry";
import UsaEarlyAppointmentItem from "../UsaEarlyAppointmentItem";
import WalletItem from "../WalletItem";
import "./styles.scss";

const allowedLogoFormats = ["image/png", "image/jpg", "image/jpeg"];

const _menu = [
	{
		key: "/home/*",
		name: "Home",
		href: "/home",
		icon: House,
		tag: undefined
	},
	{
		key: "/order/*",
		name: "Orders",
		href: "/order",
		icon: Stack,
		tag: undefined
	},
	{
		key: "/documents-and-pricing",
		name: "Documents & Pricing",
		href: "/documents-and-pricing",
		icon: FileSearch,
		tag: undefined
	}
] as const;

const TooltipWrapperDemoMode: React.FC<{ show?: boolean; children: React.ReactNode }> = ({ show = true, children }) => {
	if (!show) return <>{children}</>;

	const demoToolText = (
		<span>
			Please
			<Link to="/" className="mx-1 hover:underline">
				activate your account
			</Link>
			to apply for your first Visa order
		</span>
	);

	return (
		<Tooltip placement="rightTop" title={demoToolText}>
			{children}
		</Tooltip>
	);
};

const HomeSidebar: React.FC = () => {
	const location = useLocation();
	const { handleOpenContactUsModal } = useGlobalContext();
	const { demoMode, setDemoMode, user, uploadDocRelatedWithOrg, updateUser } = useAuthContext();
	const { rechargeWallet, fetchWalletDetails, walletDetails, isWalletLoading } = useWallet();
	const [isLogoUploading, setIsLogoUploading] = useState(false);
	const companyLogoRef = useRef<HTMLInputElement>(null);
	const isApproved = user?.organisation_id?.status === "APPROVED";
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

	const { value: isMinimized, toggle: toggleSidebar } = useBoolean(false);

	const navigate = useNavigate();

	const handleCompanyLogoChange = async (e: any) => {
		setIsLogoUploading(true);
		const file = e.target.files && e.target.files[0];
		if (!file || !user) return;
		await uploadDocRelatedWithOrg("agency_logo", file);
		setIsLogoUploading(false);
		Mixpanel.track(MIXPANEL_EVENTS.LOGO_UPLOAD);
	};

	const onAddYourLogo = () => {
		if (!companyLogoRef?.current) return;
		companyLogoRef.current.click();
	};

	const handleLogout = async () => {
		try {
			Mixpanel.track(MIXPANEL_EVENTS.LOGOUT_CLICK);
			updateUser(null);
			localStorage.removeItem("jwt-token");
			sentry.setUser(null);
			Mixpanel.reset();
			navigate("/auth");
		} catch (error) {
			toast.error("Logout failed");
			console.error(error);
		}
	};

	const handleTermsAndCondition = () => {
		Mixpanel.track(MIXPANEL_EVENTS.TNC_CLICK);
		navigate("/terms-and-condition");
	};

	const handleProfile = () => {
		Mixpanel.track(MIXPANEL_EVENTS.PROFILE_CLICK);
		navigate("/profile");
	};

	const handleDemoToggle = () => {
		Mixpanel.track(MIXPANEL_EVENTS.DEMO_TOGGLE);
		navigate("/home");
		setDemoMode(!demoMode);
	};

	const menuItems = [
		{
			key: "4",
			icon: <SignOut size={16} weight="fill" />,
			onClick: handleLogout,
			"data-item-red": true,
			label: "Logout"
		},
		{ key: "1", icon: <User size={16} weight="fill" />, onClick: handleProfile, label: "Profile" },
		{
			key: "2",
			icon: <ShieldCheck size={16} weight="fill" />,
			onClick: handleTermsAndCondition,
			label: "Terms & Condition"
		},
		{ key: "3", icon: <Phone size={16} weight="fill" />, onClick: handleOpenContactUsModal, label: "Contact Us" }
	];

	useEffect(() => {
		const getDefaultLocationSelectedKeys = () => {
			// const moduleRoute = `/${location.pathname.split("/")[1]}`;
			const moduleRoute = location.pathname;

			const matchedRoutes = matchRoutes(
				_menu.map(({ key }) => ({ path: key })),
				moduleRoute
			);

			const keys = matchedRoutes?.map((match) => match.route.path);
			return keys ? [keys[0]] : undefined;
		};

		setSelectedKeys(getDefaultLocationSelectedKeys() ?? []);

		return () => {};
	}, [location.pathname]);

	const initialOfOrgs = user?.organisation_id?.name
		?.split(" ")
		.map((word) => word[0])
		.join("")
		.toLocaleUpperCase();

	return (
		<Layout.Sider
			theme="light"
			width={isMinimized ? 60 : 240}
			className={clsx("smv-sidebar", isMinimized && "minimized")}>
			<div
				role="button"
				onClick={toggleSidebar}
				className={clsx(
					"absolute -right-3.5 top-5 z-50 inline-flex h-7 w-7 items-center justify-center gap-2 rounded-full bg-white p-1.5 ring-1 ring-gray-900/10 transition-transform",
					isMinimized && "rotate-180"
				)}>
				<CaretDoubleLeft size={16} weight="bold" className={"text-gray-500"} />
			</div>
			<div className="grid h-full max-h-screen grid-rows-[min-content_auto_min-content] overflow-y-auto overflow-x-hidden">
				<div className="logo-container sticky top-0 z-20 bg-gray-100">
					{user?.logoUrl ? (
						<img
							className="flex h-full max-h-16 w-auto justify-center mix-blend-multiply"
							src={user.logoUrl}
							alt="companyLogo"
							height={64}
						/>
					) : (
						<>
							<input
								ref={companyLogoRef}
								onChange={handleCompanyLogoChange}
								type="file"
								accept={allowedLogoFormats.join(",")}
								className="collapse"
								hidden={true}
							/>
							<button
								className="all-unset upload-logo flex h-full w-full cursor-pointer items-center justify-start gap-3 px-1 py-1.5"
								onClick={onAddYourLogo}
								disabled={isLogoUploading}>
								<span className="flex size-8 items-center justify-center rounded-[50%] border border-[#ddd6fe] bg-white text-gray-500">
									{isLogoUploading ? (
										<Loader height={24} width={24} className="text-primary-600" />
									) : (
										<UploadSimple weight="bold" />
									)}
								</span>
								<span className="upload-logo-text primary flex cursor-pointer items-center gap-2 text-sm font-medium text-gray-500">
									Add your logo
									<CaretRight weight="bold" />
								</span>
							</button>
						</>
					)}
				</div>
				<div className="scrollbar-hidden flex flex-col place-self-stretch overflow-y-auto">
					<Menu
						mode="inline"
						selectedKeys={selectedKeys}
						items={_menu.map((item) => {
							return {
								key: item.key,
								icon: <item.icon size={20} weight="fill" />,
								className: "[&.ant-menu-item-selected]:text-primary-600",
								label: (
									<>
										{item.name}
										{item.tag && (
											<span className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full bg-primary-50 px-1 py-0.5 text-[10px] font-medium leading-3 text-primary-600 ring-1 ring-primary-600">
												{item.tag}
											</span>
										)}
									</>
								),
								onClick: () => navigate(item.href)
							};
						})}
					/>
					<UsaEarlyAppointmentItem className="mx-3 mt-8" isMinimized={isMinimized} />
					<div className="mt-auto">
						{!isMinimized && walletDetails && (
							<WalletItem
								className="mx-3 mb-4 mt-8"
								amount={walletDetails.balance}
								loading={isWalletLoading}
								currency="₹"
								onRechargeClick={rechargeWallet}
								fetchWalletDetails={fetchWalletDetails}
							/>
						)}

						<Menu
							mode="inline"
							selectedKeys={[]}
							items={[
								{
									key: "demo-toggle",
									className: clsx(
										"relative flex select-none items-center",
										demoMode && isMinimized && "!bg-amber-400 !text-amber-900"
									),
									icon: <Laptop size={20} weight="fill" />,
									label: (
										<TooltipWrapperDemoMode show={!isApproved}>
											Demo Mode
											<Switch
												checked={demoMode}
												data-color="amber"
												className="absolute right-2.5 top-1/2 -translate-y-1/2"
											/>
										</TooltipWrapperDemoMode>
									),
									onClick: handleDemoToggle
								},
								{
									key: "report-bug",
									onClick: () => sentry.showFeedBackModal(),
									icon: <Megaphone className="!align-middle" size={20} weight="fill" />,
									className: "items-center [&.ant-menu-item-selected]:text-primary-600",
									label: (
										<span className="flex items-center justify-between !text-gray-500">
											Report a Bug
											<div className="inline-flex h-5 flex-col items-center justify-center gap-2 rounded-md border border-gray-200 bg-gray-400/15 px-1 py-0.5">
												<div className="text-center font-['Inter'] text-[11px] font-normal uppercase leading-tight text-current">
													F8
												</div>
											</div>
										</span>
									)
								},
								{
									key: "contact-us",
									className: "!mb-3 items-center [&.ant-menu-item-selected]:text-primary-600",
									icon: <Info className="!align-middle" size={20} weight="fill" />,
									label: "Help & support",
									onClick: handleOpenContactUsModal
								}
							]}
						/>
					</div>
				</div>
				<div className="sticky bottom-0 overflow-hidden bg-gray-100 pb-2">
					<hr className="mb-3 border-gray-200" />

					<Dropdown menu={{ items: menuItems }} placement="topRight">
						<div className={clsx("w-full", !isMinimized && "px-3")}>
							<div
								className={clsx(
									"flex w-full items-center gap-2.5 rounded-lg bg-gray-100 px-2 py-1.5",
									isMinimized
										? "justify-center"
										: "justify-start border border-solid border-gray-300 bg-white"
								)}>
								<div className="relative flex h-8 min-w-8 items-center justify-center rounded-lg border border-black border-opacity-20 bg-[radial-gradient(100%_100%_at_50%_0%,_#008BE6_0%,_#93D4FF_100%)] shadow">
									<div className="font-['Inter'] text-xl font-extrabold leading-none text-white">
										{initialOfOrgs?.slice(0, 2)}
									</div>
								</div>
								{!isMinimized && (
									<div className="animate-fade-in flex w-full cursor-pointer items-center gap-1.5 overflow-hidden">
										<div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-px overflow-hidden">
											<div className="self-stretch truncate text-ellipsis font-['Inter'] text-sm font-medium leading-tight text-gray-600">
												{user?.organisation_id?.name}
											</div>
											<div className="self-stretch truncate text-ellipsis font-['Inter'] text-xs font-normal leading-[18px] text-gray-500">
												{user?.first_name} {user?.last_name}
											</div>
										</div>
										<Gear size={20} weight="fill" className="shrink-0 text-gray-400" />
									</div>
								)}
							</div>
						</div>
					</Dropdown>
				</div>
			</div>
		</Layout.Sider>
	);
};

export default HomeSidebar;

