import Loader from "components/common/Loader";
import useAppRouter from "hooks/useAppRouter";
import { Suspense, useLayoutEffect } from "react";
import { matchRoutes, useLocation, useSearchParams } from "react-router-dom";
import { useEventListener } from "usehooks-ts";
import { Mixpanel, MIXPANEL_PAGE_VIEWS_MAP } from "utils/mixpanel";
import sentry from "utils/sentry";
import "./app.scss";

function App() {
	const appRoutes = useAppRouter();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const source = searchParams.get("source");

	useLayoutEffect(() => {
		window.scrollTo({ top: 0 });
		const matchedRoutes = matchRoutes(
			Object.keys(MIXPANEL_PAGE_VIEWS_MAP).map((_) => ({ path: _ })),
			pathname
		);
		if (!matchedRoutes || matchedRoutes.length === 0) return;

		const timer = setTimeout(() => {
			Mixpanel.track(MIXPANEL_PAGE_VIEWS_MAP[matchedRoutes[0].route.path], { source: source || undefined });
		}, 100);

		return () => clearTimeout(timer);
	}, [pathname, source]);

	useEventListener("keydown", (e: KeyboardEvent) => {
		if (e.key === "F8") {
			sentry.showFeedBackModal();
		}
	});

	return (
		<div className="main-container">
			<Suspense
				fallback={
					<div className="min-w-screen grid min-h-screen place-items-center">
						<Loader size={40} className=" text-primary-600 " />
					</div>
				}>
				{appRoutes}
			</Suspense>
		</div>
	);
}
export default App;
