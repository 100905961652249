import { apiInstance } from "api/instance";

export const uploadAgencyLogoDoc = async ({ orgId, file }: UploadAgencyDocPayload) => {
	const response = await apiInstance.putForm<ApiResponse<any>>(`organisations/${orgId}/agency-logo`, { file });
	return response.data;
};

export const updateOwnerEmail = async (orgId: string, email: string) => {
	const response = await apiInstance.patch<ApiResponse<any>>(`organisations/${orgId}`, { email });
	return response.data;
};

export const getCurrentUser = async () => {
	const response = await apiInstance.get<ApiResponse<CurrentUserAPIResponse>>("users/current");
	return response.data;
};

export const getAppConfig = () => apiInstance.get<ApiResponse<TAppConfig>>("app-configs/frontend");

export const putAppConfig = (data: Record<string, any>) =>
	apiInstance.put<ApiResponse<TAppConfig>>("app-configs/frontend", data);

export const checkUserExist = async (method: "PHONE" | "EMAIL", phone: string, email?: string) => {
	const response = await apiInstance.get<
		ApiResponse<{
			exists: boolean;
			role: string[];
		}>
	>(`auth/check-user?method=${method}&phone=${phone}&email=${email}`);
	return response.data.data;
};

export const signUpOtp = async (payload: { phone: string }) => {
	const response = await apiInstance.post<
		ApiResponse<{
			status: string;
			session_id: string;
		}>
	>("auth/signup-otp", { ...payload, retry: true });
	return response.data;
};

export const verifyOtpAndSignUp = async (payload: {
	sessionId: string;
	otp: string;
	firstName: string;
	lastName: string;
	companyName: string;
	email?: string;
}) => {
	const response = await apiInstance.post<
		ApiResponse<{
			token: string;
			user: {
				organisation_id: string;
			};
		}>
	>("auth/verify-otp-and-signup", payload);
	if (response?.data?.data?.token) {
		localStorage.setItem("jwt-token", response.data.data.token);
	}
	return response.data;
};

export const sendLoginOtp = async (payload: {
	method: "PHONE" | "EMAIL";
	phone?: string;
	email?: string;
	consumer: "biz";
	retry: boolean;
}) => {
	const response = await apiInstance.post<ApiResponse<{ session_id: string }>>("auth/send-login-otp", payload);
	return response.data;
};

export const verifyOtpAndSignIn = async (payload: { sessionId: string; otp: string }) => {
	const response = await apiInstance.post<ApiResponse<{ token: string }>>("auth/verify-login-otp", payload);
	if (response?.data?.data?.token) {
		localStorage.setItem("jwt-token", response.data.data.token);
	}
	return response.data;
};

type TBusinessDetailsFormValues = {
	company_website?: string;
	no_of_employees: string;
	company_visa_volume: string;
	location: string;
};

export const updateOrgForSignup = async (orgId: string, payload: TBusinessDetailsFormValues) => {
	const response = await apiInstance.put<ApiResponse<any>>(`organisations/update-org-for-signup/${orgId}`, payload);
	return response.data;
};

