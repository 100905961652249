import useIsMobile from "hooks/useIsMobile";
import React, { useMemo } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { ROUTES, TRoute } from "./Routes";

const convertToMobileRoutes = (routes: TRoute[]) => {
	let map: RouteObject[] = [];
	for (let ri = 0; ri < routes.length; ri++) {
		const { mobile, ...route } = routes[ri];
		if (mobile === false) {
			continue;
		}
		if (route.children) route.children = convertToMobileRoutes(route.children);
		map.push({
			...route,
			element: React.isValidElement(mobile) ? mobile : route.element
		});
	}
	return map;
};

const useAppRouter = () => {
	const isMobile = useIsMobile();

	const ROUTES_MAP = useMemo(() => {
		if (isMobile) return convertToMobileRoutes(ROUTES);
		return ROUTES;
	}, [isMobile]);

	const appRoutes = useRoutes(ROUTES_MAP);

	return appRoutes;
};

export default useAppRouter;

