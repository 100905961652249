import useIsMobile from "hooks/useIsMobile";
import React from "react";
import DesktopWalletRechargeModal from "./Components/DesktopWalletRechargeModal";
import MobileWalletRechargeModal from "./Components/MobileWalletRechargeModal";

export type TWalletRechargeModalPropsType = {
	open: boolean;
	onClose: () => void;
	onRecharge?: (amount: number) => void;
};

const WalletRechargeModal: React.FC<TWalletRechargeModalPropsType> = (props) => {
	const isMobile = useIsMobile();

	if (isMobile) {
		return <MobileWalletRechargeModal {...props} />;
	}

	return <DesktopWalletRechargeModal {...props} />;
};

export default WalletRechargeModal;

