import { apiInstance } from "./instance";

const getExt = (filename: string): string => {
	return filename.split(".").pop()?.toLowerCase() ?? "";
};
export const getFileURLfromFilename = async (filename: string) => {
	const { data } = await apiInstance.get<ApiResponse<string[]>>(
		`documents/signed-url?filename=${encodeURIComponent(filename)}`
	);

	const signedURLs: TSignedURL[] = data.data.map((url) => {
		const urlObj = new URL(url);
		const filename = urlObj.pathname.split("/").pop();
		const extension = getExt(filename as string);
		if (!filename) return { url, filename: "", extension: "" };
		return { url, filename, extension };
	});

	return signedURLs;
};

