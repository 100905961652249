export type ConfigType = {
	gravity: number;
	particle_count: number;
	particle_size: number;
	explosion_power: number;
	destroy_target: boolean;
	fade: boolean;
};

class Vector {
	x: number;
	y: number;

	constructor(x: number = 0, y: number = 0) {
		this.x = x;
		this.y = y;
	}
}

class Particle {
	size: Vector;
	position: Vector;
	velocity: Vector;
	rotation: number;
	rotation_speed: number;
	hue: number;
	opacity: number;
	lifetime: number;
	config: ConfigType;

	constructor(position: Vector, config: ConfigType) {
		this.config = config;
		this.size = new Vector(
			(16 * Math.random() + 4) * config.particle_size,
			(4 * Math.random() + 4) * config.particle_size
		);
		this.position = new Vector(position.x - this.size.x / 2, position.y - this.size.y / 2);
		this.velocity = Particle.generateVelocity(config);
		this.rotation = 360 * Math.random();
		this.rotation_speed = 10 * (Math.random() - 0.5);
		this.hue = 360 * Math.random();
		this.opacity = 100;
		this.lifetime = Math.random() + 0.25;
	}

	static generateVelocity(config: ConfigType): Vector {
		let x = Math.random() - 0.5;
		let y = Math.random() - 0.7;
		let magnitude = Math.sqrt(x * x + y * y);
		x /= magnitude;
		y /= magnitude;
		return new Vector(x * (Math.random() * config.explosion_power), y * (Math.random() * config.explosion_power));
	}

	update(deltaTime: number): void {
		this.velocity.y += this.config.gravity * (this.size.y / (10 * this.config.particle_size)) * deltaTime;
		this.velocity.x += 25 * (Math.random() - 0.5) * deltaTime;
		this.velocity.y *= 0.98;
		this.velocity.x *= 0.98;
		this.position.x += this.velocity.x;
		this.position.y += this.velocity.y;
		this.rotation += this.rotation_speed;
		if (this.config.fade) {
			this.opacity -= this.lifetime;
		}
	}

	checkBounds(windowHeight: number): boolean {
		return this.position.y - 2 * this.size.x > 2 * windowHeight;
	}

	draw(ctx: CanvasRenderingContext2D): void {
		Renderer.drawRectangle(this.position, this.size, this.rotation, this.hue, this.opacity, ctx);
	}
}

class Burst {
	particles: Particle[];
	config: ConfigType;

	constructor(position: Vector, config: ConfigType) {
		this.config = config;
		this.particles = [];
		for (let i = 0; i < config.particle_count; i++) {
			this.particles.push(new Particle(position, config));
		}
	}

	update(deltaTime: number, windowHeight: number): void {
		for (let i = this.particles.length - 1; i >= 0; i--) {
			this.particles[i].update(deltaTime);
			if (this.particles[i].checkBounds(windowHeight)) {
				this.particles.splice(i, 1);
			}
		}
	}

	draw(ctx: CanvasRenderingContext2D): void {
		for (const particle of this.particles) {
			particle.draw(ctx);
		}
	}
}

class Renderer {
	static clearScreen(ctx: CanvasRenderingContext2D, windowWidth: number, windowHeight: number): void {
		ctx.clearRect(0, 0, 2 * windowWidth, 2 * windowHeight);
	}

	static drawRectangle(
		position: Vector,
		size: Vector,
		rotation: number,
		hue: number,
		opacity: number,
		ctx: CanvasRenderingContext2D
	): void {
		ctx.save();
		ctx.beginPath();
		ctx.translate(position.x + size.x / 2, position.y + size.y / 2);
		ctx.rotate((rotation * Math.PI) / 180);
		ctx.rect(-size.x / 2, -size.y / 2, size.x, size.y);
		ctx.fillStyle = `hsla(${hue}deg, 90%, 65%, ${opacity}%)`;
		ctx.fill();
		ctx.restore();
	}
}

export class Confetti {
	canvas: HTMLCanvasElement;
	ctx: CanvasRenderingContext2D;
	config: ConfigType;
	bursts: Burst[];
	time: number;
	delta_time: number;

	constructor(config?: ConfigType) {
		this.config = config ?? defaultConfig;
		this.canvas = document.createElement("canvas");
		this.ctx = this.setupCanvasContext();
		this.bursts = [];
		this.time = new Date().getTime();
		this.delta_time = 0;
		window.requestAnimationFrame((t) => this.update(t));
	}

	setupCanvasContext(): CanvasRenderingContext2D {
		this.canvas.id = new Date().getTime().toString();
		this.canvas.width = 2 * window.innerWidth;
		this.canvas.height = 2 * window.innerHeight;
		this.canvas.style.position = "fixed";
		this.canvas.style.top = "0";
		this.canvas.style.left = "0";
		this.canvas.style.width = "calc(100%)";
		this.canvas.style.height = "calc(100%)";
		this.canvas.style.margin = "0";
		this.canvas.style.padding = "0";
		this.canvas.style.zIndex = "90";
		this.canvas.style.pointerEvents = "none";
		document.body.appendChild(this.canvas);
		window.addEventListener("resize", () => {
			this.canvas.width = 2 * window.innerWidth;
			this.canvas.height = 2 * window.innerHeight;
		});
		return this.canvas.getContext("2d")!;
	}

	pop(x: number, y: number) {
		const position = new Vector(2 * x, 2 * y);
		this.bursts.push(new Burst(position, this.config));
	}

	update(time: number): void {
		this.delta_time = (time - this.time) / 1000;
		this.time = time;

		for (let i = this.bursts.length - 1; i >= 0; i--) {
			this.bursts[i].update(this.delta_time, window.innerHeight);
			if (this.bursts[i].particles.length === 0) {
				this.bursts.splice(i, 1);
			}
		}

		this.draw();

		// Use lambda to maintain the context of 'this'
		window.requestAnimationFrame((t) => this.update(t));
	}

	draw(): void {
		Renderer.clearScreen(this.ctx, window.innerWidth, window.innerHeight);
		for (const burst of this.bursts) {
			burst.draw(this.ctx);
		}
	}
}

const defaultConfig: ConfigType = {
	gravity: 10,
	particle_count: 75,
	particle_size: 1,
	explosion_power: 25,
	destroy_target: false,
	fade: true
};

const confetti = new Confetti(defaultConfig);

export default confetti;

