export const LAYOUT_SIDEBAR_HEADER_MAP: Record<string, {sidebar: boolean, banner: boolean}> = {
	"/order/:orderId": {
		sidebar: true,
		banner: true,
	},
	"/complete-business-documents": {
		sidebar: false,
		banner: false,
	}
};

