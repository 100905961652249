import { apiInstance } from "api/instance";

export type GetCountriesQueryParamsType = {
	isSupported?: boolean;
	visaInfoAvailable?: boolean;
	symbol?: string;
};

export const getCountries = async (params?: GetCountriesQueryParamsType) => {
	const response = await apiInstance.get<ApiResponse<TCountry[]>>("countries", {
		params
	});
	return response.data;
};

