import { SealCheck } from "@phosphor-icons/react";
import { ModalProps } from "antd";
import useConfetti from "hooks/useConfetti";
import { Button, Modal } from "x-wings";

export type OrgApprovedModalPropsType = {
	open: boolean;
	onClose?: () => void;
};

const OrgApprovedModal: React.FC<OrgApprovedModalPropsType> = ({ open, onClose }) => {
	const confetti = useConfetti({
		particle_size: 3,
		particle_count: 500,
		destroy_target: false,
		explosion_power: 50,
		fade: true,
		gravity: 30
	});

	const handleOpenChange: ModalProps["afterOpenChange"] = (open) => {
		if (open) {
			confetti.pop(window.innerWidth / 2, window.innerHeight / 2);
		}
	};

	return (
		<Modal
			open={open}
			centered={true}
			footer={false}
			closeIcon={false}
			maskClosable={false}
			onCancel={onClose}
			zIndex={9999}
			className="!align-bottom md:!align-middle"
			afterOpenChange={handleOpenChange}
			styles={{
				content: { padding: "0", borderRadius: "1rem", position: "relative" },
				mask: {
					zIndex: "50",
					backgroundColor: "unset",
					backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.50) 100%)"
				},
				footer: {
					margin: 0,
					padding: 0
				},
				body: {
					display: "flex",
					flexDirection: "column",
					gap: "2.5rem",
					alignItems: "center",
					isolation: "isolate"
				}
			}}>
			<div className="absolute inset-x-0 top-0 z-[-1] inline-flex h-1/2 items-start justify-center gap-16 px-16 opacity-50">
				<div className="shrink grow basis-0 self-stretch bg-gradient-to-b from-gray-100 via-gray-100 via-60% to-transparent" />
				<div className="shrink grow basis-0 self-stretch bg-gradient-to-b from-gray-100 via-gray-100 via-60% to-transparent" />
				<div className="shrink grow basis-0 self-stretch bg-gradient-to-b from-gray-100 via-gray-100 via-60% to-transparent" />
			</div>
			<SealCheck size={132} weight="fill" className="text-emerald-600" />
			<div className="inline-flex flex-col items-center justify-start gap-2 text-emerald-600">
				<h2 className="text-center  text-3xl font-extrabold">Congratulations!</h2>
				<p className="text-center  text-xl font-bold">Your account is verified</p>
			</div>
			<p className="text-base font-semibold text-gray-700">You can start applying for real visa orders.</p>
			<Button status="success" type="primary" size="large" block onClick={onClose}>
				Start Applying for Visa Orders
			</Button>
		</Modal>
	);
};

export default OrgApprovedModal;

