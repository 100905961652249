import { Eye, EyeSlash, IconProps, Wallet } from "@phosphor-icons/react";
import { Button } from "antd";
import clsx from "clsx";
import { useEffect } from "react";
import { useBoolean } from "usehooks-ts";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { When } from "x-wings";

export type TWalletItemPropsType = {
	className?: string;
	amount: number;
	loading: boolean;
	currency: string;
	onRechargeClick?: () => void;
	fetchWalletDetails?: () => void;
};

export const WalletItem: React.FC<TWalletItemPropsType> = ({
	className,
	amount,
	currency,
	onRechargeClick,
	fetchWalletDetails
}) => {
	const { value: hideAmount, toggle: toggleHideAmount } = useBoolean(false);

	useEffect(() => {
		if (hideAmount) return;
		fetchWalletDetails?.();
	}, [hideAmount, fetchWalletDetails]);

	const eyeProps: IconProps = {
		size: 32,
		role: "button",
		weight: "bold",
		className: "ms-auto cursor-pointer p-1.5 text-gray-400 hover:text-gray-600 active:text-gray-500 select-none",
		onClick: () => {
			Mixpanel.track(MIXPANEL_EVENTS.WALLET_AMOUNT_TOGGLE, { hideAmount: !hideAmount });
			 toggleHideAmount();
		},
	};

	return (
		<div className={clsx("rounded-lg bg-gray-50 p-1 shadow-sm ring-1 ring-gray-900/10", className)}>
			<div className="px-2 pb-3 pt-1 ">
				<p className="mb-0.5 text-xs font-medium text-gray-500">Wallet balance</p>
				<p className="flex items-center gap-1 text-lg font-semibold text-gray-500">
					<span>{currency}</span>
					<When isTrue={hideAmount}>
						<span className="tracking-[-0.084px]">{"• • • •"}</span>
					</When>
					<When isTrue={!hideAmount}>
						<span>{Intl.NumberFormat("en-IN").format(amount)}</span>
					</When>
					{hideAmount ? <EyeSlash {...eyeProps} /> : <Eye {...eyeProps} />}
				</p>
			</div>
			<Button
				type="text"
				size="middle"
				block
				className="relative !bg-primary-100 hover:!bg-primary-200 active:!bg-primary-200"
				onClick={onRechargeClick}
				icon={<Wallet size={16} weight="bold" />}>
				Recharge wallet
			</Button>
		</div>
	);
};

export default WalletItem;
