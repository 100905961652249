import useIsMobile from "hooks/useIsMobile";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

const MainLayout: React.FC = () => {
	const isMobile = useIsMobile();

	if (isMobile) {
		return <MobileLayout />;
	}
	return <DesktopLayout />;
};

export default MainLayout;

