import { CheckCircle, Info, Spinner, Warning } from "@phosphor-icons/react";
import { NotificationArgsProps, notification } from "antd";

const config: TToastProps = {
	duration: 4,
	placement: "topRight"
};

type TToastProps = Omit<NotificationArgsProps, "message">;

const toast = {
	error: (message: React.ReactNode, props: TToastProps = {}) =>
		notification.error({
			...config,
			icon: <Warning />,
			message,

			...props
		}),
	info: (message: React.ReactNode, props: TToastProps = {}) =>
		notification.info({
			...config,
			icon: <Info />,
			message,

			...props
		}),
	warning: (message: React.ReactNode, props: TToastProps = {}) =>
		notification.warning({
			...config,
			icon: <Warning />,
			message,

			...props
		}),
	success: (message: React.ReactNode, props: TToastProps = {}) =>
		notification.success({
			...config,
			icon: <CheckCircle weight="fill" />,
			message,

			...props
		}),
	loading: (message: React.ReactNode, props: TToastProps = {}) =>
		notification.open({
			...config,
			message,
			...props,
			duration: 0,
			icon: <Spinner className="animate-spin" />
		}),
	destroy: notification.destroy
};

export default toast;

