import { SVGProps } from "react";
const SmvBgSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg width={152} height={136} viewBox="0 0 152 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g opacity={0.06}>
			<path
				d="M63.3507 6.8171C28.7942 20.3124 3.58549 52.4756 0.12207 90.9091L45.1689 90.9091C55.2104 90.9091 63.3507 82.7688 63.3507 72.7273L63.3507 6.8171Z"
				fill="black"
			/>
			<path
				d="M0.12207 109.091C4.71505 160.059 47.5503 200 99.7143 200C112.543 200 124.808 197.584 136.078 193.183V127.273C136.078 117.231 127.938 109.091 117.896 109.091L0.12207 109.091Z"
				fill="black"
			/>
			<path
				d="M154.26 183.828C179.236 167.543 196.485 140.397 199.307 109.091H172.442C162.4 109.091 154.26 117.231 154.26 127.273V183.828Z"
				fill="black"
			/>
			<path
				d="M199.307 90.9091C194.714 39.9409 151.878 0 99.7143 0C93.5053 0 87.4284 0.565878 81.5325 1.64888L81.5325 72.7273C81.5325 82.7688 89.6728 90.9091 99.7143 90.9091L199.307 90.9091Z"
				fill="black"
			/>
		</g>
	</svg>
);
export default SmvBgSvg;

