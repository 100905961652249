type T = {
	[key: string]: string;
};

export const statuses: T = {
	IN_PROCESS: "IN_PROCESS",
	READY_FOR_REVIEW: "READY_FOR_REVIEW",
	DOCUMENTS_PENDING: "DOCUMENTS_PENDING",
	CHANGES_REQUIRED: "CHANGES_REQUIRED",
	APPLICATION_SUBMITTED: "APPLICATION_SUBMITTED",
	UNDER_REVIEW: "UNDER_REVIEW",
	BEING_SUBMITTED: "BEING_SUBMITTED",
	ACCEPTED: "ACCEPTED",
	REJECTED: "REJECTED"
};

export const passwordCriteria =
	"Password must have 8+ chars with special chars, number, small & upper case letter (eg. Abcd@123)";


interface keyPair {
	key: string;
	label: string;
}
interface IDefaultState {
	PENDING: keyPair;
}
interface ICompletedState {
	COMPLETED: keyPair;
}
interface IInPreparationStates extends keyPair {
	QUESTIONNAIRE: keyPair & IDefaultState & ICompletedState;
	DOCUMENTS: keyPair & IDefaultState & ICompletedState;
	OCR_CONFIRMATION: keyPair & IDefaultState & ICompletedState;
}
interface IAgentReviewStates extends keyPair, IDefaultState, ICompletedState {
	IN_PROGRESS: keyPair;
	AWAITING_USER_RESPONSE: keyPair;
}

interface IInSubmissionStates extends keyPair, IDefaultState, ICompletedState {
	IN_PROGRESS: keyPair;
}
interface IFinalResultStates extends keyPair, IDefaultState {
	REJECTED: keyPair;
	ACCEPTED: keyPair;
}

interface INewVisaStates {
	CREATED: keyPair;
	IN_PROGRESS: IInPreparationStates;
	READY_TO_SUBMIT: IAgentReviewStates;
	SUBMITTING: IInSubmissionStates;
	AWAITING_RESULT: IFinalResultStates;
	COMPLETED: keyPair;
}

interface IPaymentStates extends IDefaultState {
	SERVICE_FEES_PAID: keyPair;
	PAYMENT_COMPLETED: keyPair;
}

export const newVisaStatus: INewVisaStates = {
	CREATED: {
		key: "APPLICATION_CREATED",
		label: "Application Created"
	},
	IN_PROGRESS: {
		key: "IN_PROGRESS",
		label: "In Process",
		QUESTIONNAIRE: {
			key: "QUESTIONNAIRE",
			label: "Questionnaire",
			PENDING: {
				key: "PENDING",
				label: "Pending"
			},
			COMPLETED: {
				key: "COMPLETED",
				label: "Completed"
			}
		},
		DOCUMENTS: {
			key: "DOCUMENTS",
			label: "Documents",
			PENDING: {
				key: "PENDING",
				label: "Pending"
			},
			COMPLETED: {
				key: "COMPLETED",
				label: "Completed"
			}
		},
		OCR_CONFIRMATION: {
			key: "OCR_CONFIRMATION",
			label: "OCR Confirmation",
			PENDING: {
				key: "PENDING",
				label: "Pending"
			},
			COMPLETED: {
				key: "COMPLETED",
				label: "Completed"
			}
		}
	},
	READY_TO_SUBMIT: {
		key: "READY_TO_SUBMIT",
		label: "Under Review",
		PENDING: {
			key: "PENDING",
			label: "Pending"
		},
		IN_PROGRESS: {
			key: "IN_PROGRESS",
			label: "In Progress"
		},
		AWAITING_USER_RESPONSE: {
			key: "AWAITING_USER_RESPONSE",
			label: "Changes Required"
		},
		COMPLETED: {
			key: "COMPLETED",
			label: "Completed"
		}
	},
	SUBMITTING: {
		key: "SUBMITTING",
		label: "In Submission",
		PENDING: {
			key: "PENDING",
			label: "Pending"
		},
		IN_PROGRESS: {
			key: "IN_PROGRESS",
			label: "In Progress"
		},
		COMPLETED: {
			key: "COMPLETED",
			label: "Completed"
		}
	},
	AWAITING_RESULT: {
		key: "AWAITING_RESULT",
		label: "Completed",
		ACCEPTED: {
			key: "ACCEPTED",
			label: "Accepted"
		},
		PENDING: {
			key: "PENDING",
			label: "Pending"
		},
		REJECTED: {
			key: "REJECTED",
			label: "Rejected"
		}
	},
	COMPLETED: {
		key: "COMPLETED",
		label: "Completed"
	}
};

export const paymentStatus: IPaymentStates = {
	SERVICE_FEES_PAID: {
		key: "SERVICE_FEES_PAID",
		label: "Service Fees Paid"
	},
	PAYMENT_COMPLETED: {
		key: "PAYMENT_COMPLETED",
		label: "Payment Completed"
	},
	PENDING: {
		key: "PAYMENT_PENDING",
		label: "Payment Pending"
	}
};

export const newStatusMapperForTripApplication: T = {
	APPLICATION_CREATED: "Application Created",
	PAYMENT_PENDING: "Payment Pending",
	IN_PREPARATION: "In Process",
	IN_AGENT_REVIEW: "Under Review",
	IN_SUBMISSION_COMPLETED: "Submitted",
	IN_SUBMISSION_PENDING: "Pending",
	IN_SUBMISSION_IN_PROGRESS: "Being Submitted",
	AWAITING_USER_RESPONSE: "Changes Required",
	ACCEPTED: "Accepted",
	REJECTED: "Rejected",
	COMPLETED: "Completed",
	IN_SUBMISSION: "In Submission",
	FINAL_RESULT: "Completed"
};

export const newStatusMapForTripApplication: T = {
	APPLICATION_CREATED: "Application Created",
	PAYMENT_PENDING: "Payment Pending",
	APPLICATION_SUBMITTED: "Application Submitted",
	CHANGES_REQUIRED: "Changes Required",
	TOTAL_PAYMENT_PENDING: "Payment Pending"
};

export const DOCUMENTS_TYPE_MAP: Record<DocumentsTypes, TDocDetails> = {
	PASSPORT_FRONT: {
		name: "Passport Front",
		description: "A photo of the front side of a passport"
	},
	PASSPORT_BACK: {
		name: "Passport Back",
		description: "A photo of the back side of a passport"
	},
	HOTEL_TICKET: {
		name: "Hotel Ticket",
		description: "A ticket from a hotel"
	},
	FLIGHT_TICKET: {
		name: "Flight Ticket",
		description: "A ticket from a flight"
	},
	BANK_STATEMENT: {
		name: "Bank Statement",
		description: "A bank statement"
	},
	UNCLASSIFIED: {
		name: "Unclassified",
		description: "An unclassified document"
	},
	BULK_ZIP: {
		name: "Bulk Zip",
		description: "A zip file containing multiple documents"
	},
	PHOTO: {
		name: "Photo",
		description: "A photograph"
	},
	PAN_CARD: {
		name: "Pan Card",
		description: "A photo of a pan card"
	}
};

export const INDIA_STATES = [
	{ name: "Andhra Pradesh", url: "https://www.ap.gov.in/" },
	{ name: "Arunachal Pradesh", url: "https://www.arunachalpradesh.gov.in/" },
	{ name: "Assam", url: "https://assam.gov.in/" },
	{ name: "Bihar", url: "https://state.bihar.gov.in/main/CitizenHome.html" },
	{ name: "Chhattisgarh", url: "https://www.cgstate.gov.in/en/web/guest/home" },
	{ name: "Goa", url: "https://www.goa.gov.in/" },
	{ name: "Gujarat", url: "https://gujaratindia.gov.in/" },
	{ name: "Haryana", url: "https://haryana.gov.in/" },
	{ name: "Himachal Pradesh", url: "https://himachal.gov.in/en-IN/" },
	{ name: "Jharkhand", url: "https://www.jharkhand.gov.in/" },
	{ name: "Karnataka", url: "https://www.karnataka.gov.in/english" },
	{ name: "Kerala", url: "https://kerala.gov.in/" },
	{ name: "Madhya Pradesh", url: "https://mp.gov.in/" },
	{ name: "Maharashtra", url: "https://www.maharashtra.gov.in/1125/Home" },
	{ name: "Manipur", url: "https://manipur.gov.in/" },
	{ name: "Meghalaya", url: "https://meghalaya.gov.in/" },
	{ name: "Mizoram", url: "https://mizoram.gov.in/" },
	{ name: "Nagaland", url: "https://www.nagaland.gov.in/" },
	{ name: "Odisha", url: "https://odisha.gov.in/" },
	{ name: "Punjab", url: "https://punjab.gov.in/" },
	{ name: "Rajasthan", url: "https://www.rajasthan.gov.in/Pages/default.aspx" },
	{ name: "Sikkim", url: "https://sikkim.gov.in/" },
	{ name: "Tamil Nadu", url: "https://www.tn.gov.in/" },
	{ name: "Telangana", url: "http://www.telangana.gov.in/" },
	{ name: "Tripura", url: "https://tripura.gov.in/" },
	{ name: "Uttarakhand", url: "https://uk.gov.in/" },
	{ name: "Uttar Pradesh", url: "http://up.gov.in/" },
	{ name: "West Bengal", url: "https://wb.gov.in/index.aspx" },
] as const;

export const INDIA_UTS = [
	{ name: "Andaman and Nicobar Islands", url: "https://www.andaman.gov.in/" },
	{ name: "Chandigarh", url: "http://chandigarh.gov.in/" },
	{ name: "Dadra and Nagar Haveli and Daman & Diu", url: "https://ddd.gov.in/" },
	{ name: "Delhi", url: "National Capital Territory" }, // Note: No URL provided for Delhi
	{ name: "Jammu & Kashmir", url: "https://jk.gov.in/jammukashmir/" },
	{ name: "Ladakh", url: "http://ladakh.nic.in/" },
	{ name: "Lakshadweep", url: "https://lakshadweep.gov.in/" },
	{ name: "Puducherry", url: "https://www.py.gov.in/" }
] as const;

export const INDIVIDUAL_TA_DOCUMENTS = [
	{ label: "Sole Proprietorship", value: "SOLE_PROPRIETORSHIP" },
	{ label: "OPC (One person company) certificate", value: "OPC" }
];

export const COMPANY_TA_DOCUMENTS = [
	{ label: "GST", value: "GST" },
	{ label: "Business Registration Certificate", value: "BUSINESS_REGISTRATION_CERTIFICATE" },
	{ label: "MSME / Udyam Registration", value: "MSME" }
];



