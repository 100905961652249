import { ArrowLeft, ArrowRight, Bank, CaretRight, Icon, QrCode, X } from "@phosphor-icons/react";
import { ReactComponent as BhimUpiIcon } from "assets/icons/Finance/bhim-upi-simple-icon.svg";
import { ReactComponent as ImpsIcon } from "assets/icons/Finance/imps-simple-icon.svg";
import { TWalletRechargeModalPropsType } from "components/WalletRechargeModal";
import { useState } from "react";
import WalletSvg from "screens/Orders/Components/OrderDetails/Components/Timeline/Components/PymentItem/Components/WalletSvg";
import colors from "tailwindcss/colors";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { Button, Modal, When } from "x-wings";
import NeftItem from "../NeftItem";
import SmvBgSvg from "../SmvBgSvg";
import UpiItem from "../UpiItem";

// export type WalletRechargeModalItems = "upi" | "neft" | "cards";
export type WalletRechargeModalItems = "upi" | "neft";

export const WALLET_MENU_ITEMS: Array<{
	key: WalletRechargeModalItems;
	name: string;
	icon: Icon;
	color?: string;
	divider?: boolean;
	comingSoon?: boolean;
}> = [
	{
		key: "upi",
		name: "Pay via UPI",
		icon: QrCode,
		color: "#2563EB"
	},
	{
		key: "neft",
		name: "Bank NEFT / RTGS",
		icon: Bank,
		color: "#DB2777"
	}
	// {
	// 	key: "cards",
	// 	name: "Pay with Credit Card",
	// 	icon: CreditCard,
	// 	color: "#059669"
	// }
];

export const WALLET_MENU_ITEMS_MAP = {
	upi: <UpiItem />,
	neft: <NeftItem />
	// cards: <CardsItem />
};

export const WALLET_HEADER_MAP: Record<WalletRechargeModalItems, { icon?: JSX.Element; label: string } | null> = {
	neft: {
		label: "NEFT / RTGS",
		icon: <ImpsIcon height={24} className="inline" />
	},
	upi: {
		label: "UPI / QR",
		icon: <BhimUpiIcon height={24} className="inline" />
	}
	// cards: {
	// 	label: "Credit Card"
	// }
};
const MobileWalletRechargeModal: React.FC<TWalletRechargeModalPropsType> = ({ open, onClose }) => {
	const [selected, setSelected] = useState<WalletRechargeModalItems | null>(null);

	const walletHeader = selected ? WALLET_HEADER_MAP[selected] : null;

	const handlePaymentItemClick = (key: WalletRechargeModalItems) => {
		const track: Record<WalletRechargeModalItems, any> = {
			upi: MIXPANEL_EVENTS.MOBILE.WALLET_RECHARGE_UPI_CLICK,
			neft: MIXPANEL_EVENTS.MOBILE.WALLET_RECHARGE_NEFT_CLICK
		};
		Mixpanel.track(track[key]);
		setSelected(key);
	};
	return (
		<Modal
			open={open}
			onCancel={onClose}
			afterOpenChange={(isOpen) => isOpen && setSelected(null)}
			closeIcon={false}
			destroyOnClose
			keyboard
			styles={{
				content: { padding: "4px", paddingBottom: "20px", borderRadius: "20px", background: "white" },
				footer: { margin: "0", padding: "0" },
				header: {
					borderRadius: "16px 16px 4px 4px",
					padding: "16px",
					backgroundColor: colors.gray[200],
					margin: "0",
					overflow: "hidden"
				},
				body: { padding: "16px 12px", background: "unset", boxShadow: "unset" }
			}}
			title={
				<div className="relative text-start">
					<SmvBgSvg className="absolute -bottom-4 -right-4" />
					<When isTrue={selected === null}>
						<Button
							className="absolute right-0 top-0 !size-6 !min-w-6"
							shape="circle"
							icon={<X weight="bold" />}
							onClick={onClose}></Button>
						<WalletSvg width={80} height={80} />
						<h4 className="mt-3 text-xl font-semibold leading-7 text-gray-900">Recharge your wallet</h4>
						<p className="text-base font-medium text-gray-700">
							Select one from the various methods to add money to your wallet{" "}
							<ArrowRight weight="bold" className="inline-block" />
						</p>
					</When>
					<When isTrue={selected !== null}>
						<Button
							className="mb-3 !size-6 !min-w-6"
							shape="circle"
							icon={<ArrowLeft weight="bold" />}
							onClick={() => setSelected(null)}></Button>
						<div className="flex items-center justify-start gap-3">
							{walletHeader?.icon}
							<span className="flex-1 text-xl font-semibold text-gray-900">{walletHeader?.label}</span>
						</div>
					</When>
				</div>
			}
			footer={
				<img
					width={210}
					height={16}
					src="/assets/images/logo-with-name.svg"
					alt="StampMyVisa"
					className="mx-auto my-1 h-4"
				/>
			}
			className="absolute inset-x-0 bottom-0 top-[unset] !p-2">
			<When isTrue={selected === null}>
				<div className="flex flex-col gap-4">
					{WALLET_MENU_ITEMS.map((item, index) => {
						const Icon = item.icon;
						return (
							<div
								key={index}
								className="flex h-[52px] cursor-pointer items-center gap-3 rounded-xl bg-white p-1 shadow-md ring-1 ring-gray-900/10"
								tabIndex={1}
								onClick={() => handlePaymentItemClick(item.key)}>
								<Icon size={20} weight="bold" color={item.color} className="ms-2.5" />
								<p className="flex-1 text-base font-semibold text-gray-700">{item.name}</p>
								<span className="grid h-full w-10 place-items-center rounded-e-lg rounded-s-sm bg-gray-200 p-2">
									<CaretRight
										size={24}
										weight="bold"
										className="rounded-full bg-white p-0.5 text-gray-600"
									/>
								</span>
							</div>
						);
					})}
				</div>
			</When>
			<When isTrue={selected !== null}>{selected && WALLET_MENU_ITEMS_MAP[selected]}</When>
		</Modal>
	);
};

export default MobileWalletRechargeModal;

