import { ArrowRight, Barricade, IconProps, ShieldCheck, Storefront, WarningCircle } from "@phosphor-icons/react";
import { Button, Layout } from "antd";
import { clsx } from "clsx";
import ErrorBoundary from "components/common/ErrorBoundary";
import Loader from "components/common/Loader";
import React, { Suspense, useEffect, useMemo } from "react";
import { Link, Navigate, Outlet, matchRoutes, useLocation } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";
import { useBoolean } from "usehooks-ts";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { useBanner } from "x-wings";
import Sidebar from "./Sidebar";
import { LAYOUT_SIDEBAR_HEADER_MAP } from "./constants";

const { Content } = Layout;

const verifyAccountBannerProps = {
	icon: Storefront,
	className: "!hidden md:!flex",
	message: "Please verify your Business and activate your account to apply for your first Visa order.",
	action: (
		<Link to="/complete-business-documents" className="ml-auto flex">
			<Button
				type="primary"
				className="ant-btn-warning ml-auto flex"
				shape="round"
				onClick={() => Mixpanel.track(MIXPANEL_EVENTS.KYC_ACTIVATE_ACCOUNT_CLICK)}>
				Activate your account
				<span className="ant-btn-icon">
					<ArrowRight size={16} weight="bold" />
				</span>
			</Button>
		</Link>
	)
};

const DesktopLayout: React.FC = () => {
	const { value: showDemoBar, toggle: toggleDemoBar } = useBoolean(true);
	const location = useLocation();
	const { banner: bannerFnc, containerRef: bannerContainerRef } = useBanner();
	const { user, isLoading, demoMode, businessDetailsStatus, personalDetailsStatus } = useAuthContext();

	const isApproved = user?.organisation_id?.status === "APPROVED";

	const { sidebar, banner } = useMemo(() => {
		const matches = matchRoutes(
			Object.keys(LAYOUT_SIDEBAR_HEADER_MAP).map((key) => ({ path: key })),
			location.pathname
		);

		if (matches && matches.length > 0) {
			return LAYOUT_SIDEBAR_HEADER_MAP[matches[0].route.path];
		} else {
			return { sidebar: true, banner: true };
		}
	}, [location.pathname]);

	useEffect(() => {
		if (isApproved || !user) return;

		if (businessDetailsStatus === "wait") {
			bannerFnc(verifyAccountBannerProps);
			return;
		}
		if (personalDetailsStatus === "wait") {
			bannerFnc({
				icon: Barricade,
				className: "!hidden md:!flex",
				message: "You're on Step-2! Complete the remaining steps to activate your account now.",
				action: (
					<Link to="/complete-business-documents" className="ml-auto flex">
						<Button
							type="primary"
							className="ant-btn-warning ml-auto flex"
							shape="round"
							onClick={() => Mixpanel.track(MIXPANEL_EVENTS.KYC_RESUME_STEP_2_CLICK)}>
							Resume process
							<span className="ant-btn-icon">
								<ArrowRight size={16} weight="bold" />
							</span>
						</Button>
					</Link>
				)
			});
			return;
		}
		if (user.organisation_id.status === "REJECTED") {
			bannerFnc({
				type: "error",
				icon: (args: IconProps) => <WarningCircle {...args} weight="fill" />,
				className: "!hidden md:!flex",
				message: "Account verification failed. Please re-apply for account verification.",
				action: (
					<Link to="/complete-business-documents" className="ml-auto flex">
						<Button
							type="primary"
							className="ml-auto flex"
							shape="round"
							onClick={() => Mixpanel.track(MIXPANEL_EVENTS.KYC_REJECTED_APPLY_AGAIN_CLICK)}>
							Apply again
							<span className="ant-btn-icon">
								<ArrowRight size={16} weight="bold" />
							</span>
						</Button>
					</Link>
				)
			});
			return;
		}

		if (user.organisation_id.status === "PENDING") {
			bannerFnc({
				type: "info",
				icon: ShieldCheck,
				className: "!hidden md:!flex",
				message: "Thanks for submitting your documents! Verification in progress — we'll keep you notified.",
				action: (
					<div className="inline-flex items-center gap-2 rounded-full bg-blue-100 py-1 pl-1.5 pr-4 text-sm font-medium text-blue-600">
						<span
							className="h-5 w-5 rounded-full ring-2 ring-inset ring-white"
							style={{
								backgroundImage: `conic-gradient(from 0deg at 50% 50%, currentColor 0%, currentColor 70%, #FFFFFFFF 70%)`
							}}
						/>
						<span>Estimated time:</span>
						<span>1 day</span>
					</div>
				)
			});
			return;
		}
	}, [bannerFnc, businessDetailsStatus, isApproved, personalDetailsStatus, user]);

	if (isLoading) {
		return (
			<div className="fixed inset-0 grid h-screen w-screen place-items-center overflow-hidden bg-white">
				<Loader size={40} className=" text-primary-600" />
			</div>
		);
	}

	if (!user) {
		return <Navigate to="/auth" replace />;
	}

	return (
		<Layout hasSider={sidebar}>
			{sidebar && <Sidebar />}
			<ErrorBoundary user={user}>
				<Layout>
					<Content id="app-layout-content" className="max-h-[100vh] overflow-auto bg-white">
						<div className={clsx("sticky left-0 right-0 top-0 z-20", !banner && "hidden")}>
							<div
								className={clsx(
									"box-content grid overflow-hidden transition-[grid-template-rows]",
									showDemoBar ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
								)}>
								<div
									ref={bannerContainerRef as React.RefObject<HTMLDivElement>}
									className={clsx("row-start-1 row-end-2 overflow-hidden")}></div>
							</div>
							{demoMode && (
								<div
									className={clsx(
										"absolute inset-x-0 top-full border-0 border-t border-solid border-amber-400",
										!showDemoBar && "border-t-[3px]"
									)}>
									<div
										onClick={toggleDemoBar}
										className="absolute inset-x-0 top-full mx-auto flex  w-fit cursor-pointer items-center justify-center gap-2 rounded-b border-0 border-b border-solid border-amber-500 bg-amber-400 px-2 py-0 text-xs font-bold leading-5 text-amber-900">
										DEMO MODE
									</div>
								</div>
							)}
						</div>
						{/* Using Outlet, MainLayout wrapping the PrivateRoutes in routes/index.tsx */}
						<Suspense
							fallback={
								<div className="grid h-full w-full place-items-center">
									<Loader size={40} className=" text-primary-600 " />
								</div>
							}>
							<Outlet />
						</Suspense>
					</Content>
				</Layout>
			</ErrorBoundary>
		</Layout>
	);
};

export default DesktopLayout;

