import { ArrowDownRight, ArrowUUpLeft, CurrencyInr, SortAscending, SortDescending } from "@phosphor-icons/react";
import { Dropdown } from "antd";
import { TWalletTransaction } from "context/WalletContext/Api";
import { WALLET_TRANSACTION_TYPE } from "context/WalletContext/schema";
import { useMemo } from "react";

export type SortByDropdownPropsType = {
	children: React.ReactNode;
	sortBy: "asc" | "desc";
	onSortByChange?: (val: SortByDropdownPropsType["sortBy"]) => void;
	paymentType: TWalletTransaction["type"] | undefined;
	onPaymentTypeChange?: (val: SortByDropdownPropsType["paymentType"]) => void;
};

const SortByDropdown: React.FC<SortByDropdownPropsType> = ({
	children,
	sortBy,
	paymentType,
	onSortByChange,
	onPaymentTypeChange
}) => {
	const selectedKeys = useMemo(() => {
		const keys: string[] = [sortBy];
		if (paymentType) {
			keys.push(paymentType);
		}
		return keys;
	}, [sortBy, paymentType]);

	return (
		<Dropdown
			trigger={["click"]}
			placement="bottomRight"
			menu={{
				multiple: true,
				selectedKeys: selectedKeys,
				items: [
					{
						type: "group",
						label: "TIMESTAMP",
						children: [
							{
								icon: <SortAscending size={16} weight="bold" />,
								label: "Latest to Earliest",
								key: "desc",
								onClick: () => onSortByChange?.("desc")
							},
							{
								icon: <SortDescending size={16} weight="bold" />,
								label: "Earliest to Latest",
								key: "asc",
								onClick: () => onSortByChange?.("asc")
							}
						]
					},
					{
						type: "divider"
					},
					{
						type: "group",
						label: "PAYMENT TYPE",
						children: [
							{
								icon: <ArrowDownRight size={16} weight="fill" />,
								label: "Wallet recharge",
								key: WALLET_TRANSACTION_TYPE.DEPOSIT,
								onClick: () => onPaymentTypeChange?.(WALLET_TRANSACTION_TYPE.DEPOSIT)
							},
							{
								icon: <CurrencyInr size={16} weight="regular" />,
								label: "Order payment",
								key: WALLET_TRANSACTION_TYPE.PAY_FOR_ORDER,
								onClick: () => onPaymentTypeChange?.(WALLET_TRANSACTION_TYPE.PAY_FOR_ORDER)
							},
							{
								icon: <ArrowUUpLeft size={16} weight="regular" />,
								label: "Wallet Refund",
								key: WALLET_TRANSACTION_TYPE.REFUND,
								onClick: () => onPaymentTypeChange?.(WALLET_TRANSACTION_TYPE.REFUND)
							}
						]
					}
				]
			}}>
			{children}
		</Dropdown>
	);
};

export default SortByDropdown;




