import { Link, WarningCircle } from "@phosphor-icons/react";
import { Popover } from "antd";
import { ReactComponent as ImpsIcon } from "assets/icons/Finance/imps-icon.svg";
import toast from "components/common/Toaster";
import { useWallet } from "context";
import { TWalletVirtualAccountReceiver } from "context/WalletContext/Api";
import { copyText } from "utils";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { clsx } from "x-wings";
import ShieldCheckIcon from "../ShieldCheckIcon";

/**
 * !!!IMPORTANT!!!
 * 
 * Update Mixpanel events in the following components:
 */

export type TNeftItemLabelKey = keyof Pick<
	TWalletVirtualAccountReceiver["BANK_TRANSFER"],
	"ifsc" | "account_number" | "bank_name" | "name" | "account_type"
>;
const NEFT_DETAILS_LABEL_MAP: Record<TNeftItemLabelKey, string> = {
	ifsc: "IFSC Code",
	account_number: "Account Number",
	bank_name: "Bank Name",
	name: "Account Holder Name",
	account_type: "Account Type"
};

const NeftItem = () => {
	const { virtualAccount, isVirtualAccountLoading } = useWallet();

	const handleCopy = (label: string, text: string) => {
		Mixpanel.track(MIXPANEL_EVENTS.WALLET_COPY_BANK_DETAILS, { label });
		copyText(text);
		toast.info(label + " copied!", {
			icon: <Link />
		});
	};

	return (
		<div className="relative flex h-full flex-col items-stretch md:p-8">
			<ImpsIcon className="mx-auto mb-10 hidden md:block" />
			{/*<p className="mb-10 hidden text-center text-sm font-medium text-gray-500 md:block">
				Send your funds on these bank details below:
			</p>
			<div className="grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-x-7 gap-y-5 md:gap-x-12 md:gap-y-8">
				 {error && (
					<p className="text-center text-xs font-medium text-rose-600 col-span-full">
						Oops! Something went wrong. Please try again later. If the problem continues, please contact
						support.
					</p>
				)} 
				{isVirtualAccountLoading &&
					Array.from({ length: 4 }).map((_, index) => (
						<div key={index} className="animate-pulse space-y-2">
							<div className="h-4 w-3/4 rounded bg-gray-200" />
							<div className="h-4 w-1/2 rounded bg-gray-200" />
						</div>
					))}
				{virtualAccount &&
					!isVirtualAccountLoading &&
					Object.entries(NEFT_DETAILS_LABEL_MAP).map(([key, value]) => {
						if (virtualAccount["BANK_TRANSFER"][key as TNeftItemLabelKey])
							return (
								<div key={key} className="">
									<p className="mb-0.5 text-sm font-normal text-gray-700">{value}:</p>
									<div className="flex items-center text-lg font-medium text-gray-900">
										{virtualAccount["BANK_TRANSFER"][key as TNeftItemLabelKey]}
										<CopySimple
											role="button"
											size={20}
											className="ms-auto cursor-pointer text-gray-400"
											onClick={() =>
												handleCopy(
													value,
													virtualAccount["BANK_TRANSFER"][key as TNeftItemLabelKey] ?? ""
												)
											}
										/>
									</div>
								</div>
							);
					})}
			</div> */}
			{/*<p className="ms-0.5 mt-5 text-center text-xs text-gray-400 md:text-start">
				Axis Bank Users need to add beneficiary details under Other Bank Accounts (and not the same bank
				account).
			</p>
			<Alert className="mt-4 md:mt-auto" />*/}
			<ComeBackSoon />
			<TermsAndConditionPopover />
		</div>
	);
};

const TermsAndConditionPopover: React.FC = () => (
	<Popover
		trigger={["click"]}
		arrow={false}
		zIndex={9999}
		placement="topRight"
		destroyTooltipOnHide
		rootClassName="[&_.ant-popover-inner]:!p-0 [&_.ant-popover-inner]:!ring-1 [&_.ant-popover-inner]:!ring-gray-900/10 [&_.ant-popover-title]:!mb-0 [&_.ant-popover-inner]:!shadow-lg"
		title={
			<div className="inline-flex cursor-pointer items-center gap-1.5 px-3 py-2.5 text-green-800">
				<ShieldCheckIcon width={16} height={16} />
				Terms & Conditions
			</div>
		}
		content={
			<div className="flex flex-col gap-3 border-t border-gray-900/10 p-3">
				<div className="border-s border-rose-600 bg-gradient-to-r from-[#F3F4F6] to-transparent px-2.5 text-[10px] font-normal leading-[15px] text-gray-700">
					SMV Wallet can be used for
					<br /> payments towards all your
					<br /> SMV orders
				</div>
				<div className="border-s border-rose-600 bg-gradient-to-r from-[#F3F4F6] to-transparent px-2.5 text-[10px] font-normal leading-[15px] text-gray-700">
					Any balance in wallet can not <br />
					be transferred back to a bank
					<br /> accounts as per RBI guidelines
				</div>
			</div>
		}>
		<span className="absolute right-2 top-2 hidden h-7 cursor-pointer items-center gap-1.5 rounded-md bg-emerald-100 p-1.5 pe-2.5 text-green-800 shadow-sm ring-1 ring-gray-900/10 transition-[background-color] md:inline-flex [&.ant-popover-open]:!bg-gray-200">
			<ShieldCheckIcon width={16} height={16} />
			Terms & Conditions
		</span>
	</Popover>
);

const Alert: React.FC<{ className?: string }> = ({ className }) => (
	<div className={clsx("flex items-start gap-3 rounded-lg border-x-2 border-rose-600 bg-rose-50 p-3", className)}>
		<WarningCircle size={20} weight="fill" className="shrink-0 text-rose-600" />
		<p className="flex-1 text-sm font-medium text-rose-950">
			Please use your <span className="font-semibold">Primary Banking App/Website</span> to transfer funds!
			Transaction made using GPay, PhonePe, PayTm will not reflect as Bank Transfer.
		</p>
	</div>
);

const ComeBackSoon: React.FC = () => (
	<div className=" m-6 flex items-start gap-3 rounded-lg border-x-2 border-blue-600 bg-blue-50 p-3">
		<WarningCircle size={20} weight="fill" className="shrink-0 text-blue-600" />
		<p className="flex-1 text-sm font-medium text-blue-950">
			We are committed to enhancing the security of your payments. Please note that bank transfer functionality
			will be enabled shortly. Thank you for your patience as we work to provide you with a safer transaction
			experience.
		</p>
	</div>
);

export default NeftItem;

