import { Buildings, Users } from "@phosphor-icons/react";
import { Tabs } from "antd";
import { Modal } from "x-wings";
import { LABELS } from "./constant";

const ContactUsModal = ({ isModalOpen, handleCancel }: { isModalOpen: boolean; handleCancel: () => void }) => {
	return (
		<Modal
			footer={null}
			title="Contact us"
			open={isModalOpen}
			onCancel={handleCancel}
			destroyOnClose
			className="[&_.ant-drawer-body]:!pt-0">
			<Tabs
				defaultActiveKey="pointOfContacts"
				className="[&_.ant-tabs-tab]:!mt-1"
				items={[
					{
						label: (
							<span className="inline-flex items-center gap-1">
								<Users /> Point of contacts
							</span>
						),
						key: "pointOfContacts",
						children: (
							<div className="mt-4 grid min-h-max grid-cols-1 gap-3 gap-x-4">
								<div>
									<h3 className="font-semibold">Relationship Manager (Maharashtra)</h3>
									<p className="light-gray">{LABELS.RelationshipManagerName}</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`tel:${LABELS.RelationshipManagerPhone.replace(/\D/g, "")}`}>
											{LABELS.RelationshipManagerPhone}
										</a>
									</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`mailto:${LABELS.RelationshipManagerEmail}`}>
											{LABELS.RelationshipManagerEmail}
										</a>
									</p>
								</div>
								<div>
									<h3 className="font-semibold">Relationship Manager (Delhi)</h3>
									<p className="light-gray">{LABELS.RelationshipManagerName2}</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`tel:${LABELS.RelationshipManagerPhone2.replace(/\D/g, "")}`}>
											{LABELS.RelationshipManagerPhone2}
										</a>
									</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`mailto:${LABELS.RelationshipManagerEmail2}`}>
											{LABELS.RelationshipManagerEmail2}
										</a>
									</p>
								</div>
								<div>
									<h3 className="font-semibold">Relationship Manager (Gujarat)</h3>
									<p className="light-gray">{LABELS.RelationshipManagerName3}</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`tel:${LABELS.RelationshipManagerPhone3.replace(/\D/g, "")}`}>
											{LABELS.RelationshipManagerPhone3}
										</a>
									</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`mailto:${LABELS.RelationshipManagerEmail3}`}>
											{LABELS.RelationshipManagerEmail3}
										</a>
									</p>
								</div>
								<div>
									<h3 className="font-semibold">Relationship Manager (Kolkata)</h3>
									<p className="light-gray">{LABELS.RelationshipManagerName4}</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`tel:${LABELS.RelationshipManagerPhone4.replace(/\D/g, "")}`}>
											{LABELS.RelationshipManagerPhone4}
										</a>
									</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`mailto:${LABELS.RelationshipManagerEmail4}`}>
											{LABELS.RelationshipManagerEmail4}
										</a>
									</p>
								</div>
								<div>
									<h3 className="font-semibold">Tech POC</h3>
									<p className="light-gray">{LABELS.PocName}</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`tel:${LABELS.PocNumber.replace(/\D/g, "")}`}>
											{LABELS.PocNumber}
										</a>
									</p>
									<p className="light-gray">
										<a
											className="hover:text-primary-600 hover:underline"
											href={`mailto:${LABELS.PocEmail}`}>
											{LABELS.PocEmail}
										</a>
									</p>
								</div>
							</div>
						)
					},
					{
						label: (
							<span className="inline-flex items-center gap-1">
								<Buildings /> Offices
							</span>
						),
						key: "offices",
						children: (
							<div className="mt-4 grid grid-cols-1 gap-3 gap-x-4">
								<div>
									<h3 className="font-semibold">Mumbai office</h3>
									<p className="light-gray max-w-[240px] text-pretty">{LABELS.MumbaiOfficeAddress}</p>
								</div>
								<div>
									<h3 className="mt-3 font-semibold">Delhi office</h3>
									<p className="light-gray max-w-[240px]">{LABELS.DelhiOfficeAddress}</p>
								</div>
								<div>
									<h3 className="mt-3 font-semibold">Ahmedabad office</h3>
									<p className="light-gray max-w-[240px]">{LABELS.AhmedabadOfficeAddress}</p>
								</div>
							</div>
						)
					}
				]}
			/>
		</Modal>
	);
};

export default ContactUsModal;

