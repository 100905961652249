export const LABELS = {
	RelationshipManagerName: "Rohit Varshney",
	RelationshipManagerPhone: "9867381991",
	RelationshipManagerEmail: "rohit.varshney@stampmyvisa.com",

	RelationshipManagerName2: "Vishal Kapoor",
	RelationshipManagerPhone2: "9355808004",
	RelationshipManagerEmail2: "vishal.kapoor@stampmyvisa.com",

	RelationshipManagerName3: "Harshal Patel",
	RelationshipManagerPhone3: "9892361991",
	RelationshipManagerEmail3: "harshal.patel@stampmyvisa.com",

	RelationshipManagerName4: "Suvradeep Biswas",
	RelationshipManagerPhone4: "9355808003",
	RelationshipManagerEmail4: "suvradeep.biswas@stampmyvisa.com",

	PocName: "Mohan Swaroop",
	PocNumber: "9355808001",
	PocEmail: "mohan.swaroop@stampmyvisa.com",

	MumbaiOfficeAddress:
		"Asha House  Block no 1, Gr Floor, Office Number 2, Near Bata Showroom, Dr Babasaheb Ambedkar Road ,Dadar TT, Dadar East Mumbai-400014",
	DelhiOfficeAddress: "514, 5th floor, Indraprakash Building, Barakhamb road, New Delhi-110001",
	AhmedabadOfficeAddress: "1021 Shivalik shilp2 Opposite ITC Narmada Vastrapur-380015 Ahmedabad"
} as const;

