import { SVGProps } from "react";
const ShieldCheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g filter="url(#filter0_i_2311_17169)">
			<path
				d="M13.3339 8.6664C13.3339 11.9997 11.0006 13.6664 8.22724 14.6331C8.08201 14.6823 7.92426 14.6799 7.78057 14.6264C5.00057 13.6664 2.66724 11.9997 2.66724 8.6664V3.99973C2.66724 3.82292 2.73747 3.65335 2.8625 3.52833C2.98752 3.4033 3.15709 3.33306 3.3339 3.33306C4.66724 3.33306 6.3339 2.53306 7.4939 1.51973C7.63514 1.39906 7.81481 1.33276 8.00057 1.33276C8.18633 1.33276 8.366 1.39906 8.50724 1.51973C9.6739 2.53973 11.3339 3.33306 12.6672 3.33306C12.844 3.33306 13.0136 3.4033 13.1386 3.52833C13.2637 3.65335 13.3339 3.82292 13.3339 3.99973V8.6664Z"
				fill="#079455"
			/>
		</g>
		<path
			d="M13.3339 8.6664C13.3339 11.9997 11.0006 13.6664 8.22724 14.6331C8.08201 14.6823 7.92426 14.6799 7.78057 14.6264C5.00057 13.6664 2.66724 11.9997 2.66724 8.6664V3.99973C2.66724 3.82292 2.73747 3.65335 2.8625 3.52833C2.98752 3.4033 3.15709 3.33306 3.3339 3.33306C4.66724 3.33306 6.3339 2.53306 7.4939 1.51973C7.63514 1.39906 7.81481 1.33276 8.00057 1.33276C8.18633 1.33276 8.366 1.39906 8.50724 1.51973C9.6739 2.53973 11.3339 3.33306 12.6672 3.33306C12.844 3.33306 13.0136 3.4033 13.1386 3.52833C13.2637 3.65335 13.3339 3.82292 13.3339 3.99973V8.6664Z"
			stroke="#085D3A"
			strokeWidth={0.8}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<g filter="url(#filter1_dd_2311_17169)">
			<path
				d="M10.4175 5.38372L7.06874 8.8615L6.10942 7.90352C6.02 7.83084 5.90861 7.79059 5.79339 7.78932C5.67817 7.78805 5.56592 7.82583 5.47492 7.89651C5.38681 7.96312 5.32666 8.06014 5.30619 8.16868C5.28572 8.27721 5.3064 8.38948 5.36419 8.48361L6.51871 10.2294C6.63352 10.3858 6.8264 10.4811 7.03028 10.474C7.23416 10.4669 7.41264 10.3586 7.51627 10.1946C7.69042 9.9625 10.879 5.86335 10.879 5.86335C11.3006 5.4112 10.7551 5.02928 10.4248 5.38346L10.4175 5.38372Z"
				fill="white"
			/>
		</g>
		<defs>
			<filter
				id="filter0_i_2311_17169"
				x={2.26733}
				y={0.932861}
				width={11.4666}
				height={14.9355}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={0.8} />
				<feGaussianBlur stdDeviation={0.8} />
				<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow_2311_17169" />
			</filter>
			<filter
				id="filter1_dd_2311_17169"
				x={5.29858}
				y={5.24438}
				width={5.73389}
				height={6.02998}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={0.8} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2311_17169" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={0.4} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_2311_17169" result="effect2_dropShadow_2311_17169" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2311_17169" result="shape" />
			</filter>
		</defs>
	</svg>
);
export default ShieldCheckIcon;

