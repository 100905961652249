import {
	ArrowDownRight,
	ArrowUUpLeft,
	CreditCard,
	ExclamationMark,
	FunnelSimple,
	MagnifyingGlass
} from "@phosphor-icons/react";
import { Input, Table } from "antd";
import { ReactComponent as BhimUpiIcon } from "assets/icons/Finance/bhim-upi-simple-icon.svg";
import { ReactComponent as ImpsIcon } from "assets/icons/Finance/imps-simple-icon.svg";
import { PAYMENT_MODES, WALLET_TRANSACTION_STATUS, WALLET_TRANSACTION_TYPE } from "context/WalletContext/schema";
import dayjs from "dayjs";
import useWalletHistory from "hooks/useWalletHistory";
import colors from "tailwindcss/colors";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { Button, Flag, When } from "x-wings";
import SortByDropdown from "../SortByDropdown";

export const PAYMENT_METHOD_MAP: Record<keyof typeof PAYMENT_MODES, { icon?: JSX.Element; label: string } | null> = {
	bank_transfer: {
		label: "NEFT",
		icon: <ImpsIcon height={10} width="auto" />
	},
	upi: {
		label: "BHIM UPI",
		icon: <BhimUpiIcon height={10} width="auto" />
	},
	credit_card: {
		label: "Credit Card",
		icon: <CreditCard size={18} weight="fill" color={colors.gray[500]} />
	},
	wallet: null
};

const HistoryItem: React.FC = () => {
	const {
		isWalletHistoryLoading,
		walletHistory,
		totalCount,
		page,
		sortBy,
		paymentType,
		pageSize,
		resetTableScroll,
		handlePageChange,
		handlePaymentTypeChange,
		handleSortByChange,
		setSearchText
	} = useWalletHistory({});
	return (
		<div className="w-full px-2 pt-2">
			<div className="flex flex-col items-stretch overflow-y-auto">
				<div className="flex w-full items-center gap-6 border-b border-gray-200 px-3 pb-4 pt-2">
					<span className="text-base font-medium text-gray-700">Wallet history</span>
					<Input
						placeholder="Search Order ID"
						className="!h-7 flex-1"
						onChange={(e) => {
							Mixpanel.track(MIXPANEL_EVENTS.WALLET_HISTORY_SEARCH);
							setSearchText(e.target.value);
						}}
						prefix={<MagnifyingGlass size={15} weight="bold" className="text-gray-400" />}
					/>
					<SortByDropdown
						sortBy={sortBy}
						paymentType={paymentType}
						onSortByChange={handleSortByChange}
						onPaymentTypeChange={handlePaymentTypeChange}>
						<Button
							type="text"
							className="!h-7 !bg-none !text-gray-500 hover:!bg-gray-50 hover:!text-gray-600 active:!bg-gray-50 active:!text-gray-600"
							icon={<FunnelSimple size={16} weight="bold" />}>
							Sort by
						</Button>
					</SortByDropdown>
				</div>
				<Table
					bordered={false}
					className="!border-none [&_.ant-table-cell:first-child]:!ps-2 [&_.ant-table-cell:last-child]:!pe-2 [&_.ant-table-cell]:!border-none [&_.ant-table-cell]:!px-2 [&_.ant-table-cell]:!py-2"
					showHeader={false}
					pagination={{
						pageSize: pageSize,
						current: page,
						total: totalCount,
						position: ["bottomCenter"],
						onChange: handlePageChange,
						showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
					}}
					loading={isWalletHistoryLoading}
					scroll={{ scrollToFirstRowOnChange: resetTableScroll, y: 460 }}
					columns={[
						{
							key: "country",
							width: 230,
							render(value, record, index) {
								return (
									<div className="flex items-center gap-3">
										<div className="flex size-8 items-center justify-center rounded-full bg-white p-1.5 shadow-sm ring-1 ring-gray-900/10">
											{record.status === WALLET_TRANSACTION_STATUS.FAILED ? (
												<ExclamationMark size={20} weight="fill" className="text-red-700" />
											) : (
												<>
													<When isTrue={record.type === WALLET_TRANSACTION_TYPE.DEPOSIT}>
														<ArrowDownRight
															size={20}
															weight="fill"
															className="text-emerald-600"
														/>
													</When>
													<When
														isTrue={record.type === WALLET_TRANSACTION_TYPE.PAY_FOR_ORDER}>
														<Flag
															code={record.country?.flag_symbol as any}
															hasDropShadow={false}
															hasBorder
															hasBorderRadius
															size="s"
															gradient="real-linear"
														/>
													</When>
													<When isTrue={record.type === WALLET_TRANSACTION_TYPE.REFUND}>
														<ArrowUUpLeft
															size={20}
															weight="fill"
															className="text-red-600"
														/>
													</When>
												</>
											)}
										</div>
										<div className="flex flex-col items-start justify-center gap-1">
											<span className="text-sm font-normal text-gray-800">
												{record.status === WALLET_TRANSACTION_STATUS.FAILED ? (
													"Recharge failure"
												) : (
													<>
														<When isTrue={record.type === WALLET_TRANSACTION_TYPE.DEPOSIT}>
															Wallet recharge
														</When>
														<When
															isTrue={
																record.type === WALLET_TRANSACTION_TYPE.PAY_FOR_ORDER
															}>
															{record.country?.name}
														</When>
														<When isTrue={record.type === WALLET_TRANSACTION_TYPE.REFUND}>
															Wallet refund
														</When>
													</>
												)}
											</span>
											{record.metadata.smvOrderId &&
												record.status !== WALLET_TRANSACTION_STATUS.FAILED && (
													<span className="text-xs font-normal text-gray-500">
														{record.metadata.smvOrderId}
													</span>
												)}
										</div>
									</div>
								);
							}
						},
						{
							key: "amount",
							width: 150,
							render(value, record, index) {
								return (
									<div className="flex flex-col items-start gap-1">
										<span className="text-sm font-normal text-gray-800">
											₹{Intl.NumberFormat("en-IN").format(record.amount)}
										</span>
										{/* <span className="text-xs font-normal text-gray-500">{record.id}</span> */}
									</div>
								);
							}
						},
						{
							key: "payment",
							align: "end",
							dataIndex: "payment_mode",
							render(paymentMode, record, index) {
								return (
									<div className="flex flex-col items-end justify-center gap-1">
										{record.type === WALLET_TRANSACTION_TYPE.DEPOSIT && (
											<span className="inline-flex items-center gap-2 whitespace-nowrap text-sm font-normal text-gray-800">
												{PAYMENT_METHOD_MAP[paymentMode as keyof typeof PAYMENT_MODES]?.icon}
												{PAYMENT_METHOD_MAP[paymentMode as keyof typeof PAYMENT_MODES]?.label}
											</span>
										)}
										<span className="text-xs font-normal text-gray-500">
											{dayjs(record.created_at).format("DD-MM-YY | hh:mm A")}
										</span>
									</div>
								);
							}
						}
					]}
					rowKey="id"
					dataSource={walletHistory}
				/>
			</div>
		</div>
	);
};

export default HistoryItem;

