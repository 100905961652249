import { useGlobalContext } from "context";

const MOBILE_VIEW_WIDTH = 768;

const useIsMobile = () => {
	const { isMobile } = useGlobalContext();
	return isMobile;
};

export default useIsMobile;

