import { useEffect } from "react";
import confetti, { ConfigType } from "./confetti";

const useConfetti = (config: ConfigType) => {
	useEffect(() => {
		confetti.config = config;
	}, [config]);

	return confetti;
};

export default useConfetti;

