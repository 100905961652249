import {
	init as initSentry,
	browserTracingIntegration as sentryBrowserTracingIntegration,
	feedbackIntegration as sentryFeedbackIntegration,
	replayIntegration as sentryReplayIntegration,
	setUser as sentrySetUser
} from "@sentry/react";
import { ENV, SENTRY_DSN } from "env";
import "./style.scss";

class Sentry {
	private browserTracingIntegration = sentryBrowserTracingIntegration();
	private feedbackIntegration = sentryFeedbackIntegration({
		colorScheme: "system",
		autoInject: false
	});
	private replayIntegration = sentryReplayIntegration({
		blockAllMedia: false,
		maskAllText: false,
		networkCaptureBodies: true
	});

	constructor() {
		this.init();
	}

	private init() {
		initSentry({
			dsn: SENTRY_DSN,
			tracesSampleRate: 1.0,
			tracePropagationTargets: [
				"localhost",
				/^https:\/\/(dev\.internal|qa\.internal|internal)\.stampmyvisa\.com/
			],
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 1.0,
			environment: ENV,
			integrations: [this.browserTracingIntegration, this.replayIntegration, this.feedbackIntegration]
		});
	}

	public setUser(user: Record<string, any> | null) {
		sentrySetUser(user);
	}

	public async showFeedBackModal() {
		if (document.getElementById("sentry-feedback-form")) return;
		const form = await this.feedbackIntegration.createForm();
		form.appendToDom();
		form.open();
	}
}

const sentry = new Sentry();

export default sentry;
